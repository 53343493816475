import {
  Box,
  BoxProps,
  Button,
  Dialog,
  DialogContent,
  Fade,
  FadeProps,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
  TypographyProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { yupResolver } from "@hookform/resolvers/yup";
import { useRouter } from "next/router";
import { forwardRef, ReactElement, Ref, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { setSceneScript } from "src/store/apps/Storyboard";

import { CloseIcon } from "../../../public/images/pages/icons/Icons";
import { createNewProject, editProjectNameDescription } from "../../config/api";

const defaultValues = {
  projectName: "",
  templateId: "1",
  projectDescription: "",
};

const schema = yup.object().shape({
  templateId: yup.string().required("Template is required"),
  projectName: yup
    .string()
    .required("Project name is required")
    .max(100, "Only 100 characters allowed"),
  projectDescription: yup
    .string()
    .max(750, "Project description should not exceed 750 characters."),
});

// ** Styled Components

const TypographyStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  letterSpacing: "0.18px",
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down("md")]: { marginTop: theme.spacing(8) },
}));

const CreateProjectButton = styled(Button)({
  textTransform: "none",
});

const FormContainer = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  padding: "2% 5%",
}));

const ScreenContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  padding: "2rem",
  // height: '80vh'
}));

const ImageContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  alignItem: "center",
  justifyContent: "center",
  padding: "1rem",
}));

const Transition = forwardRef(function Transition(
  props: FadeProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />;
});

const CreateNewProject = (props: any) => {
  const router = useRouter();
  const [disableButton, setButtonDisability] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const projectNames = props.allProjects.map((project) => project.name);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const name = watch("projectName");
  const desc = watch("projectDescription");

  useEffect(() => {
    setErrorMessage("");
  }, [name, desc]);

  useEffect(() => {
    if (props.editMode) {
      setValue("projectName", props.selectedProject.name);
      setValue("projectDescription", props.selectedProject.description);
    }
  }, [props.editMode, props.show, props.selectedProject]);

  const dispatch = useDispatch();
  const onSubmit = async (values: any) => {
    try {
      setButtonDisability(true);
      if (projectNames.includes(values.projectName)) {
        if (props.selectedProject?.name === values.projectName) {
        } else {
          throw new Error("A project with the same name already exists");
        }
      }
      if (props.editMode) {
        const data = await editProjectNameDescription({
          id: props.selectedProject.id,
          name: values.projectName,
          description: values.projectDescription,
        });

        props.setProjects((old) =>
          old.map((item) =>
            item.id === props.selectedProject.id
              ? {
                  ...item,
                  name: data.name,
                  description: data.description,
                }
              : item,
          ),
        );
        setValue("projectName", "");
        setValue("projectDescription", "");
        props.setEditProjectDetails(false);
        props.setShow(false);
        setButtonDisability(false);
      } else {
        dispatch(setSceneScript(""));
        const data = await createNewProject(values);
        router.push({
          pathname:
            "/projects/" +
            data.project.id +
            "/scene/" +
            data.project.scenes[0].id +
            "/script",
        });
      }
    } catch (e: any) {
      if (e.message === "A project with the same name already exists") {
        setErrorMessage(e.message);
        console.error(e.message);
      }

      setButtonDisability(false);
    } finally {
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        open={props.show}
        maxWidth="sm"
        scroll="body"
        onClose={() => {
          if (props.editMode) {
            props.setEditProjectDetails(false);
          }
          props.setShow(false);
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => props.setShow(true)}
      >
        <DialogContent
          sx={{
            position: "relative",
            padding: "0",
          }}
        >
          {/* Close Button */}
          {props.show ? (
            <IconButton
              aria-label="close"
              onClick={() => {
                if (props.editMode) {
                  props.setEditProjectDetails(false);
                }
                props.setShow(false);
                control._reset();
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}

          {/* <ScreenContainer sx={{backgroundImage: `url(/images/pages/login/rect-${imageSource}-${theme.palette.mode}.png)`}}> */}
          <ScreenContainer>
            <FormContainer>
              {props.editMode ? (
                <Box sx={{ mb: 6, textAlign: "center" }}>
                  <TypographyStyled variant="h4">{`Edit Project Details`}</TypographyStyled>
                </Box>
              ) : (
                <Box sx={{ mb: 6, textAlign: "center" }}>
                  <TypographyStyled variant="h4">{`Create New Project!`}</TypographyStyled>
                  <Typography variant="body2">
                    Get started on your next project with ease.
                  </Typography>
                </Box>
              )}
              <ImageContainer>
                {/* <img src="/images/pages/createNewProject.png" alt="Create New Project" /> */}
              </ImageContainer>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                {/* <FormControl fullWidth sx={{ mb: 4 }}>
                  <InputLabel id="template-id-label">Template</InputLabel>
                  <Controller
                    name="templateId"
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Select
                        labelId="template-id-label"
                        label="Template"
                        id="template-id"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                      >
                        <MenuItem value="">Select Template ID</MenuItem>
                        <MenuItem value="1">Enchanted Flames</MenuItem>
                        <MenuItem value="2">Artificial Connection</MenuItem>
                        <MenuItem value="3">The Forgotten Relic</MenuItem>
                        <MenuItem value="4">Nature's Redemption</MenuItem>
                        <MenuItem value="8">Letting Go</MenuItem>
                      </Select>
                    )}
                  />
                  {errors.templateId && (
                    <FormHelperText sx={{ color: 'error.main' }}>
                      {errors.templateId.message}
                    </FormHelperText>
                  )}
                </FormControl> */}
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name="projectName"
                    control={control}
                    rules={{ required: "Project Name is required." }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoFocus
                        label="Project Name*"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={Boolean(errors.projectName)}
                      />
                    )}
                  />
                  {errors.projectName && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.projectName.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name="projectDescription"
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        multiline
                        rows={4}
                        label="Project Description"
                        placeholder="Add project description here..."
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        error={Boolean(errors.projectDescription)}
                      />
                    )}
                  />
                  {errors.projectDescription && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.projectDescription.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <CreateProjectButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ mb: 7 }}
                  disabled={disableButton}
                >
                  {disableButton
                    ? "Preparing Your Project!"
                    : props.editMode
                      ? "Update"
                      : "Create New Project"}
                </CreateProjectButton>
              </form>
              {errorMessage !== "" ? (
                <Typography sx={{ color: "red" }}>
                  A project with the same name already exists
                </Typography>
              ) : (
                <></>
              )}
            </FormContainer>
          </ScreenContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateNewProject;
