import { CircularProgress } from "@mui/material";

import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import ProjectList from "src/components/Projects/ProjectsList";
import { projects, ProjectsResponse } from "src/config/api";

function App() {
  const router = useRouter();

  const [serverData, setServerData] = useState<ProjectsResponse | null>(null);

  useEffect(() => {
    (async () => {
      const response = await projects();
      if (!response.isProfileCompleted) {
        router.push("/user-profile/complete");
      }
      setServerData(response);
    })();
  }, []);

  return (
    <>
      {serverData?.projects ? (
        <ProjectList
          projects={serverData.projects}
          isSubscribed={serverData.isSubscribed}
          subscriptionStatus={serverData.subscriptionStatus}
        />
      ) : (
        <CircularProgress
          disableShrink
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </>
  );
}

export default App;
