import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { BoxProps } from "@mui/system";

import { Icon } from "@iconify/react";
import { useRouter } from "next/router";
import { useState } from "react";
import { toast } from "react-hot-toast";

import { deleteProject, ProjectDetails } from "src/config/api";
import { useAuth } from "src/config/auth";
import { getColorFromArray } from "src/libs/utils";

import CreateNewProject from "./CreateNewProject";

const TopHeader = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

const TypographyStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  letterSpacing: "0.18px",
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down("md")]: { marginTop: theme.spacing(8) },
}));

type ProjectListProps = {
  isSubscribed: boolean;
  subscriptionStatus: string;
  projects: ProjectDetails[];
};

const ProjectList = (props: ProjectListProps) => {
  const router = useRouter();
  const [show, setShow] = useState(false);
  const [showSubscribePopup, setSubscribePopup] = useState(false);
  const [showWaitListPopup, setShowWaitListPopup] = useState(false);

  const [projects, setProjects] = useState(props.projects);
  const [isSubscribed] = useState(props.isSubscribed);

  const { userData } = useAuth();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProject, setSelectedProject] = useState<ProjectDetails | null>(
    null,
  );
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [editProjectDetails, setEditProjectDetails] = useState(false);

  const menuStyle = {
    background: theme.palette.customColors.tableHeaderBg, // Replace with your desired background color
  };

  const navigateToProjectDetails = (projectId: string, sceneId: string) => {
    router.push({
      pathname: "projects/" + projectId + "/scene/" + sceneId + "/script",
    });
  };

  const createNewProject = () => {
    if (userData.user.isSuperuser) {
      setShow(true);
    } else if (isSubscribed) {
      if (props.subscriptionStatus == "Confirmed") {
        setShow(true);
      } else if (props.subscriptionStatus == "WaitList") {
        setShowWaitListPopup(true);
      }
    } else {
      setSubscribePopup(true);
    }
  };

  const createFirstProject = () => {
    router.push({
      pathname: "/projects/createFirst",
    });
  };

  const columns = [
    { name: "Project Name", align: "left" },
    { name: "Date", align: "center" },
    { name: "Workspace", align: "left" },
    { name: "Hours Rendered", align: "center" },
    { name: "# Scenes", align: "center" },
    { name: "Progress", align: "left" },
  ];

  // Static Avatars
  const avatars = [
    { name: "Alice", avatar: "/images/avatars/1.png" },
    { name: "Bob", avatar: "/images/avatars/2.png" },
  ];

  const handleMenuOpen = (event, project) => {
    setAnchorEl(event.currentTarget);
    setSelectedProject(project);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteProject = () => {
    handleMenuClose();
    setShowConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
    handleMenuClose();
    setSelectedProject(null);
  };

  const handleConfirmDelete = async () => {
    if (selectedProject === null) return;

    try {
      const deleteResponse = await deleteProject(selectedProject.id);
      // Remove the deleted project from the frontend project list
      const updatedProjectsList = projects.filter(
        (project) => project.id !== selectedProject.id,
      );
      setProjects(updatedProjectsList);

      toast.success(`${selectedProject.name} deleted successfully`, {
        duration: 2000,
        position: "bottom-left",
      });

      setSelectedProject(null);
    } catch {
    } finally {
      setShowConfirmation(false);
      handleMenuClose();
    }
  };

  const handleEditProject = () => {
    handleMenuClose();
    setEditProjectDetails(true);
    createNewProject();
  };

  const handleViewProject = () => {
    if (selectedProject === null) return;
    router.push({
      pathname:
        "projects/" +
        selectedProject.id +
        "/scene/" +
        selectedProject.sceneIds[0] +
        "/script",
    });
  };

  // Calulated time spent in months
  const getTimeSpent = (createdDate: any) => {
    let months;
    const currentDate = new Date();
    createdDate = new Date(createdDate);
    months = (currentDate.getFullYear() - createdDate.getFullYear()) * 12;
    months -= createdDate.getMonth();
    months += currentDate.getMonth();
    return months <= 0 ? 0 : months;
  };

  const getDate = (date: any) => {
    return new Date(date).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const getHoursRendered = (project: ProjectDetails) => {
    const renderedDuration = project.renderedDuration;
    const secondsRendered = Math.floor(renderedDuration);
    const hoursRendered = Math.floor(renderedDuration / 3600);
    const minutesRendered = Math.floor(renderedDuration / 60);

    return `${
      hoursRendered > 9 ? hoursRendered : 0 + hoursRendered.toString()
    } Hours`;
  };

  const theme1 = createTheme({
    palette: {
      secondary: {
        main: "#9c27b0",
      },
    },
  });

  const handleSubscribePopupClose = () => {
    setSubscribePopup(false);
  };

  const handleSubscribeClick = () => {
    router.push("/subscription");
  };

  const findProjectOwner = (project: ProjectDetails) => {
    const res = project.users.find((x) => {
      return x.role.name === "Owner";
    });
    return res?.user?.firstName
      ? res?.user?.firstName + " " + res?.user?.lastName
      : res?.user?.email;
  };

  const isNonEmpty = (text: string) => {
    // Targeting specific invisible characters
    const invisibleCharRegex = /[\u200B\u200C\u200D\uFEFF\xA0]/g;
    // Remove invisible characters and check if anything remains
    return text.replace(invisibleCharRegex, "").trim().length > 0;
  };

  const getProjectDescription = (
    project: ProjectDetails,
    scriptText: string,
  ) => {
    if (isNonEmpty(project.description ?? "")) {
      return project.description;
    } else if (isNonEmpty(scriptText)) {
      return scriptText;
    } else {
      return "No script written yet";
    }
  };

  const isUserContainsRoleOverProject = (role: string) => {
    const userProjectRoles = selectedProject?.users.map((u) => {
      if (u.userId == userData.user.id) return u.role?.name;
      return null;
    });
    return userProjectRoles?.includes(role);
  };
  return (
    <>
      <TopHeader sx={{ p: 6 }}>
        <Box>
          <TypographyStyled variant="h5">{`My Projects`}</TypographyStyled>
          <Typography variant="body2">
            Manage all your script-to-film projects in one place. Access any
            projects quickly here.
          </Typography>
        </Box>
        <Box>
          <Button variant="contained" onClick={() => createNewProject()}>
            Create Project
          </Button>
        </Box>
      </TopHeader>
      {projects?.length > 0 ? (
        <Grid sx={{ p: 6 }} container spacing={6}>
          {projects?.map((project, index) => {
            return (
              <Grid key={index} item xs={12} md={5} lg={4} xl={3}>
                <Card sx={{ minHeight: "100%" }}>
                  <CardContent
                    onClick={(e) => {
                      e.preventDefault();
                      navigateToProjectDetails(project.id, project.sceneIds[0]);
                    }}
                    sx={{
                      height: "240px",
                      position: "relative",
                      cursor: "pointer",
                      mb: "0",
                      paddingTop: "3.5rem",
                      zIndex: 10,
                      background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${project.backgroundImage}) no-repeat`,
                      backgroundSize: "cover",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                      }}
                    >
                      <IconButton
                        aria-label="more"
                        aria-controls="project-menu"
                        aria-haspopup="true"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleMenuOpen(event, project);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Box>

                    <Box sx={{ bottom: 0 }}>
                      <Typography
                        onClick={(e) => {
                          e.preventDefault();
                          navigateToProjectDetails(
                            project.id,
                            project.sceneIds[0],
                          );
                        }}
                        sx={{
                          mr: 1,
                          fontWeight: 700,
                          fontSize: "18px",
                          cursor: "pointer",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {project.name}
                      </Typography>
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontWeight: 600,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {"By "}
                        {findProjectOwner(project)}
                      </Typography>
                      <Box>
                        <Typography sx={{ color: "text.secondary" }}>
                          {project.account.name}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          mb: 3,
                          pr: "1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "text.secondary",
                            mt: "0.5rem",
                            fontSize: "10px",
                          }}
                        >
                          {getProjectDescription(
                            project,
                            project.openingScenePreview ?? "",
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        bottom: 8,
                        left: 0,
                        px: 5,
                        position: "absolute",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            mr: 1,
                            fontWeight: 500,
                            fontSize: "13px",
                          }}
                        >
                          Scenes:
                        </Typography>
                        <Typography
                          sx={{
                            color: "text.secondary",
                            fontSize: "13px",
                          }}
                        >
                          {project.sceneIds.length}
                        </Typography>
                      </Box>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Typography
                          sx={{
                            mr: 1,
                            fontWeight: 600,
                            fontSize: "13px",
                          }}
                        >
                          Created:
                        </Typography>
                        <Typography
                          sx={{
                            color: "text.secondary",
                            fontSize: "13px",
                          }}
                        >
                          {getDate(project.createdAt)}
                        </Typography>
                      </Stack>
                    </Box>
                  </CardContent>

                  <Divider sx={{ my: "0 !important" }} />
                  <Box
                    sx={{
                      height: "35%",
                      padding: 0,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 2,
                      width: "100%",
                      p: 2,
                      px: 4,
                    }}
                  >
                    <Stack direction={"row"} alignSelf={"center"}>
                      <AvatarGroup className="pull-up">
                        {project.users.map((projectUser, index) => (
                          <Avatar
                            sx={{
                              color: "#2f2f2f",
                              width: 30,
                              height: 30,
                              fontSize: "0.8rem",
                              backgroundColor: getColorFromArray(index)!,
                            }}
                          >
                            {projectUser?.user?.firstName
                              ? `${projectUser?.user?.firstName
                                  ?.charAt(0)
                                  .toUpperCase()}${projectUser?.user?.lastName
                                  ?.charAt(0)
                                  .toUpperCase()}`
                              : projectUser?.user?.email
                                  ?.charAt(0)
                                  .toUpperCase()}
                          </Avatar>
                        ))}
                      </AvatarGroup>
                    </Stack>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        py: 1,
                        my: "4px",
                        ml: "1rem",
                        gap: 2,
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "5px",
                          display: "inline-flex",
                          padding: "3px 10px",
                          color: "#FDB528",
                          fontSize: "12px",
                          fontWeight: "500",
                          cursor: "pointer",
                          backgroundColor: "rgba(253, 181, 40, 0.1)",
                        }}
                      >{`Credits Used: ${project.creditsUsed}`}</div>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box sx={{ p: 6, textAlign: "center", paddingTop: "10%" }}>
          <Typography variant="body1">No projects found.</Typography>
          <Typography variant="body2" sx={{ mt: 3 }}>
            Create a new project to get started.
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: 4 }}
            onClick={() => createNewProject()}
          >
            Create Project
          </Button>
        </Box>
      )}

      {editProjectDetails === true ? (
        <CreateNewProject
          show={show}
          setShow={setShow}
          allProjects={projects}
          setProjects={setProjects}
          editMode={editProjectDetails}
          selectedProject={selectedProject}
          setEditProjectDetails={setEditProjectDetails}
        />
      ) : (
        <CreateNewProject
          show={show}
          setShow={setShow}
          allProjects={projects}
        />
      )}
      <Menu
        id="project-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          style: menuStyle,
        }}
      >
        {(selectedProject?.isDemoProject ||
          (userData && isUserContainsRoleOverProject("Viewer"))) && (
          <MenuItem onClick={() => handleViewProject()}>View</MenuItem>
        )}
        {!selectedProject?.isDemoProject &&
          ((userData && isUserContainsRoleOverProject("Editor")) ||
            isUserContainsRoleOverProject("Owner")) && (
            <MenuItem onClick={() => handleEditProject()}>Edit</MenuItem>
          )}
        {!selectedProject?.isDemoProject &&
          userData &&
          isUserContainsRoleOverProject("Owner") && (
            <MenuItem onClick={handleDeleteProject}>Delete</MenuItem>
          )}
      </Menu>

      <Dialog
        open={showConfirmation}
        onClose={handleCancelDelete}
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">
          Are you sure you want to delete {selectedProject?.name}?
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              color: "text.secondary",
              height: 50,
              mb: 2,
              maxHeight: 60,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            This action is irreversible. Deleting the project will permanently
            remove it from the system.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showSubscribePopup}
        onClose={handleSubscribePopupClose}
        aria-labelledby="subscribe-dialog-title"
      >
        <DialogTitle id="subscribe-dialog-title" textAlign={"center"}>
          Please Subscribe
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              color: "text.secondary",
              height: 50,
              mb: 2,
              maxHeight: 60,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            Please subscribe to our payment plan for creating custom project.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubscribePopupClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubscribeClick}
            color="primary"
            autoFocus
          >
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={showWaitListPopup}
        onClose={() => {
          setShowWaitListPopup(false);
        }}
      >
        <DialogContent
          sx={{
            pb: (theme) => `${theme.spacing(6)} !important`,
            px: (theme) => [
              `${theme.spacing(5)} !important`,
              `${theme.spacing(15)} !important`,
            ],
            pt: (theme) => [
              `${theme.spacing(8)} !important`,
              `${theme.spacing(12.5)} !important`,
            ],
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              "& svg": {
                mb: 8,
                color: "warning.main",
              },
            }}
          >
            <Icon fontSize="5.5rem" icon={"mdi:clock-outline"} />
            <Typography variant="h4" sx={{ mb: 5 }}>
              {"You are on wait list."}
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              {"You are on wait list. Please wait."}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            px: (theme) => [
              `${theme.spacing(5)} !important`,
              `${theme.spacing(15)} !important`,
            ],
            pb: (theme) => [
              `${theme.spacing(8)} !important`,
              `${theme.spacing(12.5)} !important`,
            ],
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setShowWaitListPopup(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectList;
